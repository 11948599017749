import axios from "axios";
import { useStyletron } from "baseui";
import { Block } from "baseui/block";
import { KIND, SIZE } from "baseui/button";
import { StyledLink } from "baseui/link";
import { ModalBody, ModalFooter, ModalHeader } from "baseui/modal";
import { Option, Value } from "baseui/select";
import { Skeleton } from "baseui/skeleton";
import { DURATION, useSnackbar } from "baseui/snackbar";
import { LabelLarge, LabelMedium, LabelSmall } from "baseui/typography";
import React, { MouseEvent, useEffect, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { useHistory } from "react-router";
import { AlertCircle, Check, Plus, X } from "tabler-icons-react";

import Button from "../../../components/button";
import Card from "../../../components/card";
import Cell from "../../../components/cell";
import Content from "../../../components/content";
import FormattedValue from "../../../components/formatted-value";
import Grid from "../../../components/grid";
import Header from "../../../components/header";
import Modal from "../../../components/modal";
import { ArticlesSelect } from "../../../components/select";
import Table from "../../../components/table";
import { BASIC_AUTH } from "../../../constants";
import { useLoading } from "../../../contexts/loading-context";
import { SnackbarError, SnackbarSuccess } from "../../../utils/snackbarTypes";
import { Article } from "../../Articles/articles";
import { Section } from "../sections";

export default function SectionsIndex(): React.ReactElement {
  const [css, theme] = useStyletron();
  const history = useHistory();
  const { enqueue } = useSnackbar();
  const { isFetching, isLoading, setIsLoading, setIsFetching } = useLoading();

  const [articleIds, setArticleIds] = useState<Array<number>>([]);
  const [isOpen, setIsOpen] = useState(false);
  const [positionEdited, setPositionEdited] = useState<number>();
  const [selectedArticles, setSelectedArticles] = useState<
    Array<Option | undefined>
  >([]);
  const [changes, setChanges] = useState(0);

  const { isError, data, refetch } = useQuery(
    "sections",
    async () =>
      (
        await axios.get(
          `${process.env.REACT_APP_API_URL}/admin/sections`,
          BASIC_AUTH
        )
      ).data
  );

  const {
    isError: isFeaturedArticlesError,
    data: featuredArticlesData,
    refetch: refetchFeaturedArticles,
  } = useQuery(
    "featured-articles",
    async () =>
      (
        await axios.get(
          `${process.env.REACT_APP_API_URL}/admin/articles/paginated`,
          {
            ...BASIC_AUTH,
            params: {
              sort: "position",
              isRecommended: true,
              limit: 5,
            },
          }
        )
      ).data
  );

  const postData = async () => {
    const { data } = await axios.patch(
      `${process.env.REACT_APP_API_URL}/admin/articles/position`,
      { articleIds },
      BASIC_AUTH
    );
    return data;
  };

  const { isError: isMutationError, mutateAsync } = useMutation(
    "articlesReposition",
    postData
  );

  const onSubmit = async (): Promise<void> => {
    setIsLoading(true);

    try {
      const data = await mutateAsync();

      enqueue({
        message: "Zapisano pomyślnie",
        overrides: SnackbarSuccess,
        startEnhancer: ({ size }: { size: number }) => <Check size={size} />,
      });

      refetchFeaturedArticles();
      setIsOpen(false);
    } catch (error) {
      enqueue(
        {
          message: "Wystąpił błąd",
          overrides: SnackbarError,
          startEnhancer: ({ size }: { size: number }) => (
            <AlertCircle size={size} />
          ),
        },
        DURATION.long
      );
    } finally {
      setIsLoading(false);
    }
  };

  const close = () => setIsOpen(false);

  useEffect(() => {
    if (isError)
      enqueue(
        {
          message: "Wystąpił błąd",
          overrides: SnackbarError,
          startEnhancer: ({ size }: { size: number }) => (
            <AlertCircle size={size} />
          ),
        },
        DURATION.long
      );
  }, [isError]);

  useEffect(() => {
    if (featuredArticlesData?.results) {
      setArticleIds(
        featuredArticlesData.results.map((article: Article) => article.id)
      );

      setSelectedArticles(
        featuredArticlesData.results.map((article: Article) => ({
          id: article.id,
          label: article.title,
        }))
      );
    }
  }, [featuredArticlesData]);

  const columns = React.useMemo(
    () => [
      {
        Header: "Nazwa",
        id: "name",
        Cell: ({ row }: { row: any }) => (
          <StyledLink
            onClick={(event: MouseEvent) => {
              event.preventDefault();
              history.push(`/sections/${row?.original?.id}`);
            }}
            href={`/sections/${row?.original?.id}`}
          >
            <FormattedValue showBlankWhenEmpty>
              {row.original.name}
            </FormattedValue>
          </StyledLink>
        ),
      },
      {
        Header: (
          <Block display="flex" justifyContent="center">
            Widoczność
          </Block>
        ),
        accessor: "isVisible",
        Cell: ({ cell }: { cell: any }) => (
          <Block display="flex" alignItems="center" justifyContent="center">
            <FormattedValue dataType="visibility-icon" showBlankWhenEmpty>
              {!cell?.value}
            </FormattedValue>
          </Block>
        ),
      },
      {
        id: "actions",
        Cell: ({ row }: { row: any }) => (
          <div
            className={css({
              display: "flex",
              justifyContent: "flex-end",
            })}
          >
            <Button
              kind={KIND.secondary}
              size={SIZE.mini}
              $as="a"
              href={`/sections/${row?.original?.id}/edit`}
              onClick={(event: MouseEvent) => {
                event.preventDefault();
                history.push(`/sections/${row?.original?.id}/edit`);
              }}
            >
              Edytuj
            </Button>
            <Button
              kind={KIND.secondary}
              size={SIZE.mini}
              $as="a"
              href={`/sections/${row?.original?.id}`}
              $style={{ marginLeft: "10px" }}
              onClick={(event: MouseEvent) => {
                event.preventDefault();
                history.push(`/sections/${row?.original?.id}`);
              }}
            >
              Pokaż
            </Button>
          </div>
        ),
      },
    ],
    []
  );

  useEffect(() => {
    if (isError)
      enqueue(
        {
          message: "Wystąpił błąd",
          overrides: SnackbarError,
          startEnhancer: ({ size }: { size: number }) => (
            <AlertCircle size={size} />
          ),
        },
        DURATION.long
      );
  }, [isError]);

  useEffect(() => {
    if (data) refetch();
    setIsFetching(true);
  }, []);

  useEffect(() => {
    if (data && featuredArticlesData) setIsFetching(false);
  }, [data, featuredArticlesData]);

  return (
    <article>
      <Header
        title="Sekcje"
        recordsNum={data?.length}
        labels={["Lista"]}
        buttons={[
          {
            label: "Dodaj sekcję",
            kind: KIND.secondary,
            $as: "a",
            href: "/sections/create",
            startEnhancer: <Plus size={18} />,
            onClick: (event: MouseEvent) => {
              event.preventDefault();
              history.push("/sections/create");
            },
          },
        ]}
      />
      <Content>
        <Grid>
          <Cell
            span={12}
            $style={{ position: "relative", paddingBottom: "28px" }}
          >
            <LabelLarge marginBottom="scale500">Polecane</LabelLarge>

            {isFetching ? (
              <Skeleton rows={0} height="300px" width="100%" animation />
            ) : (
              featuredArticlesData && (
                <>
                  <div
                    className={css({
                      padding: "20px",
                      backgroundColor: theme.colors.inputFill,
                      marginBottom: "10px",
                      borderTopLeftRadius: theme.borders.radius200,
                      borderTopRightRadius: theme.borders.radius200,
                      borderBottomRightRadius: theme.borders.radius200,
                      borderBottomLeftRadius: theme.borders.radius200,
                    })}
                  >
                    <Grid fill>
                      <Cell span={6}>
                        <Card
                          headerImage={
                            featuredArticlesData.results[0]?.bannerUrl
                          }
                        >
                          <>
                            <LabelMedium>
                              {featuredArticlesData.results[0]?.title}{" "}
                              <Button
                                size="mini"
                                onClick={() => {
                                  setPositionEdited(0);
                                  setIsOpen(true);
                                }}
                              >
                                Zmień
                              </Button>
                            </LabelMedium>
                            {selectedArticles[0] &&
                              featuredArticlesData.results[0]?.title !==
                                selectedArticles[0]?.label && (
                                <LabelSmall>
                                  {`Zmiana na: ${selectedArticles[0]?.label}`}
                                </LabelSmall>
                              )}
                          </>
                        </Card>
                      </Cell>
                      <Cell span={3}>
                        <Card
                          headerImage={
                            featuredArticlesData.results[1]?.bannerUrl
                          }
                        >
                          <>
                            <LabelMedium>
                              {featuredArticlesData.results[1]?.title}{" "}
                              <Button
                                size="mini"
                                onClick={() => {
                                  setPositionEdited(1);
                                  setIsOpen(true);
                                }}
                              >
                                Zmień
                              </Button>
                            </LabelMedium>

                            {selectedArticles[1] &&
                              featuredArticlesData.results[1]?.title !==
                                selectedArticles[1]?.label && (
                                <LabelSmall>
                                  {`Zmiana na: ${selectedArticles[1]?.label}`}
                                </LabelSmall>
                              )}
                          </>
                        </Card>

                        <Card
                          headerImage={
                            featuredArticlesData.results[2]?.bannerUrl
                          }
                        >
                          <>
                            <LabelMedium>
                              {featuredArticlesData.results[2]?.title}{" "}
                              <Button
                                size="mini"
                                onClick={() => {
                                  setPositionEdited(2);
                                  setIsOpen(true);
                                }}
                              >
                                Zmień
                              </Button>
                            </LabelMedium>

                            {selectedArticles[2] &&
                              featuredArticlesData.results[2]?.title !==
                                selectedArticles[2]?.label && (
                                <LabelSmall>
                                  {`Zmiana na: ${selectedArticles[2]?.label}`}
                                </LabelSmall>
                              )}
                          </>
                        </Card>
                      </Cell>
                      <Cell span={3}>
                        <Card
                          headerImage={
                            featuredArticlesData.results[3]?.bannerUrl
                          }
                        >
                          <>
                            <LabelMedium>
                              {featuredArticlesData.results[3]?.title}{" "}
                              <Button
                                size="mini"
                                onClick={() => {
                                  setPositionEdited(3);
                                  setIsOpen(true);
                                }}
                              >
                                Zmień
                              </Button>
                            </LabelMedium>
                            {selectedArticles[3] &&
                              featuredArticlesData.results[3]?.title !==
                                selectedArticles[3]?.label && (
                                <LabelSmall>
                                  {`Zmiana na: ${selectedArticles[3]?.label}`}
                                </LabelSmall>
                              )}
                          </>
                        </Card>
                        <Card
                          headerImage={
                            featuredArticlesData.results[4]?.bannerUrl
                          }
                        >
                          <>
                            <LabelMedium>
                              {featuredArticlesData.results[4]?.title}{" "}
                              <Button
                                size="mini"
                                onClick={() => {
                                  setPositionEdited(4);
                                  setIsOpen(true);
                                }}
                              >
                                Zmień
                              </Button>
                            </LabelMedium>

                            {selectedArticles[4] &&
                              featuredArticlesData.results[4]?.title !==
                                selectedArticles[4]?.label && (
                                <LabelSmall>
                                  {`Zmiana na: ${selectedArticles[4]?.label}`}
                                </LabelSmall>
                              )}
                          </>
                        </Card>
                      </Cell>
                    </Grid>
                  </div>

                  <Block display="flex" alignItems="center">
                    <Button
                      disabled={
                        isLoading ||
                        featuredArticlesData?.results
                          .map((article: Article) => article.id)
                          .every(
                            (val: number, index: number) =>
                              val === articleIds[index]
                          ) ||
                        new Set(articleIds).size !== articleIds.length
                      }
                      isLoading={isLoading}
                      onClick={onSubmit}
                      $style={{
                        marginRight: "16px",
                      }}
                    >
                      Zapisz układ
                    </Button>

                    {featuredArticlesData?.results
                      .map((article: Article) => article.id)
                      .every(
                        (val: number, index: number) =>
                          val === articleIds[index]
                      ) && (
                      <LabelSmall color="gray">
                        Brak zmian do zapisania.
                      </LabelSmall>
                    )}

                    {new Set(articleIds).size !== articleIds.length && (
                      <LabelSmall color="negative">
                        W układzie występuje duplikat.
                      </LabelSmall>
                    )}
                  </Block>
                </>
              )
            )}
          </Cell>

          <Cell span={12} $style={{ position: "relative" }}>
            {isFetching ? (
              <Skeleton rows={0} height="300px" width="100%" animation />
            ) : (
              data && (
                <Table<Section>
                  columns={columns}
                  data={data.results}
                  noOffset
                />
              )
            )}
          </Cell>
        </Grid>
      </Content>

      <Modal
        onClose={close}
        closeable={!isLoading}
        isOpen={isOpen}
        animate
        unstable_ModalBackdropScroll
        autoFocus
        overrides={{
          Root: {
            style: {
              zIndex: 14,
            },
          },
          Dialog: {
            style: {
              borderTopLeftRadius: theme.borders.radius300,
              borderTopRightRadius: theme.borders.radius300,
              borderBottomRightRadius: theme.borders.radius300,
              borderBottomLeftRadius: theme.borders.radius300,
              paddingTop: theme.sizing.scale300,
              paddingBottom: theme.sizing.scale600,
              paddingRight: theme.sizing.scale300,
              paddingLeft: theme.sizing.scale300,
            },
          },
          Close: {
            component: () => (
              <span
                className={css({
                  position: "absolute",
                  right: theme.sizing.scale600,
                  top: theme.sizing.scale600,
                  cursor: "pointer",
                  color: "#444",
                  ":hover": {
                    color: "black",
                  },
                })}
                onClick={close}
              >
                <X size={20} />
              </span>
            ),
          },
        }}
        size={SIZE.default}
      >
        <ModalHeader>Wybierz artykuł</ModalHeader>
        <ModalBody>
          {positionEdited !== undefined && (
            <ArticlesSelect
              clearable={false}
              value={
                selectedArticles[positionEdited]
                  ? ([selectedArticles[positionEdited]] as Value)
                  : undefined
              }
              placeholder="Wybierz"
              onChange={(params) => {
                setSelectedArticles((selectedArticles) => {
                  const newArray = selectedArticles;
                  selectedArticles[positionEdited] = params.option;

                  return newArray;
                });

                setArticleIds((articlesIds) => {
                  const newArray = articlesIds;
                  articlesIds[positionEdited] = params.option?.id as number;
                  return newArray;
                });

                setChanges((changes) => changes + 1);
                setIsOpen(false);
              }}
            />
          )}
        </ModalBody>
        <ModalFooter>
          <Button kind="secondary" onClick={close}>
            Zamknij
          </Button>
        </ModalFooter>
      </Modal>
    </article>
  );
}
