import axios from "axios";
import { useStyletron } from "baseui";
import { Block } from "baseui/block";
import { KIND, SIZE } from "baseui/button";
import { Skeleton } from "baseui/skeleton";
import { DURATION, useSnackbar } from "baseui/snackbar";
import { Spinner } from "baseui/spinner";
import debounce from "lodash.debounce";
import React, { MouseEvent, useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useQuery } from "react-query";
import { useHistory } from "react-router";
import { useLocation } from "react-router-dom";
import {
  AlertCircle,
  Clock,
  EyeCheck,
  FileLike,
  Pencil,
  Plus,
} from "tabler-icons-react";

import Button from "../../../components/button";
import Cell from "../../../components/cell";
import Content from "../../../components/content";
import FormControl from "../../../components/form-control";
import FormattedValue from "../../../components/formatted-value";
import Grid from "../../../components/grid";
import Header from "../../../components/header";
import { ControlledInput } from "../../../components/input";
import ObjectTag from "../../../components/object-tag";
import Pagination from "../../../components/pagination";
import SortingTableHeader, {
  SortDirection,
} from "../../../components/sorting-table-header";
import Table from "../../../components/table";
import Thumbnail from "../../../components/thumbnail";
import { BASIC_AUTH, PAGE_SIZE } from "../../../constants";
import { useLoading } from "../../../contexts/loading-context";
import { SnackbarError } from "../../../utils/snackbarTypes";
import { ArticleCategory } from "../../ArticleCategories/article-categories";
import { Article } from "../articles";

enum FieldName {
  Title = "title",
  Status = "status",
  PublishedAt = "publishedAt",
  Category = "category",
  Editor = "editor",
  IsRecommended = "isRecommended",
  IsFeatured = "isFeatured",
  IsPinned = "isPinned",
  IsSponsored = "isSponsored",
  ViewCount = "viewCount",
}

const FilterableFields = [
  { id: FieldName.IsRecommended, label: "Polecany" },
  { id: FieldName.IsFeatured, label: "Wyróżniony" },
  { id: FieldName.IsPinned, label: "Przypięty" },
  { id: FieldName.IsSponsored, label: "Sponsorowany" },
];

export default function ArticlesIndex(): React.ReactElement {
  const location = useLocation();
  let pageNumberFromUrl = Number(location.search.split("?page=").pop());
  if (pageNumberFromUrl < 1) {
    pageNumberFromUrl = 1;
  }
  const [paginationNumber, setPaginationNumber] = useState(pageNumberFromUrl);
  const [pageSize] = useState(PAGE_SIZE);
  const query = new URLSearchParams(useLocation().search);
  const pageNumber = parseInt(query.get("page") as string);
  const [sortBy, setSortBy] = useState<FieldName | null>(null);
  const [sortDirection, setSortDirection] = useState<SortDirection | null>(
    null
  );
  const [searchingInputValue, setSearchingInputValue] = useState("");
  const [choosedCategory, setChoosedCategory] = useState<number>();
  const [choosedFilter, setChoosedFilter] = useState<string>();
  const [css, theme] = useStyletron();
  const history = useHistory();
  const { enqueue } = useSnackbar();
  const { isFetching, setIsFetching } = useLoading();

  const { control } = useForm<{ searchingPhrase: string }>();
  const [searchingPhrase, setSearchingPhrase] = useState("");
  const debouncedSetSearchingPhrase = useCallback(
    debounce(setSearchingPhrase, 1000),
    []
  );

  const isDateFuture = (date: Date) => {
    return new Date(date).valueOf() > new Date().valueOf();
  };

  const {
    isError,
    isLoading: isQueryLoading,
    isFetching: isQueryFetching,
    data,
    refetch,
  } = useQuery(
    "articles",
    async () =>
      (
        await axios.get(
          `${process.env.REACT_APP_API_URL}/admin/articles/paginated`,
          {
            ...BASIC_AUTH,
            params: {
              categoryId: choosedCategory,
              limit: PAGE_SIZE,
              offset: (paginationNumber - 1) * pageSize,
              ...(searchingPhrase && { title: `iLike:%${searchingPhrase}%` }),
              sort: `${sortDirection === SortDirection.ASC ? "" : "-"}${
                sortBy || "publishedAt"
              }`,
              ...(choosedFilter === FieldName.IsRecommended && {
                isRecommended: true,
              }),
              ...(choosedFilter === FieldName.IsFeatured && {
                isFeatured: true,
              }),
              ...(choosedFilter === FieldName.IsPinned && {
                isPinned: true,
              }),
              ...(choosedFilter === FieldName.IsSponsored && {
                isSponsored: true,
              }),
            },
          }
        )
      ).data
  );

  const { data: categoriesData, refetch: categoriesRefetch } = useQuery(
    "articleCategoriesTags",
    async () =>
      (
        await axios.get(
          `${process.env.REACT_APP_API_URL}/admin/article-categories/paginated`,
          {
            ...BASIC_AUTH,
            params: {
              limit: 100,
            },
          }
        )
      ).data
  );

  const handleSorting = (column: FieldName) => {
    setSortBy(column);
    setSortDirection(
      sortDirection === null
        ? SortDirection.DESC
        : sortDirection === SortDirection.ASC
        ? SortDirection.DESC
        : SortDirection.ASC
    );
  };

  const columns = React.useMemo(
    () => [
      {
        Header: (
          <SortingTableHeader
            onClick={() => handleSorting(FieldName.Title)}
            sortDirection={sortBy === FieldName.Title ? sortDirection : null}
            $style={{ minWidth: "300px" }}
          >
            Tytuł
          </SortingTableHeader>
        ),
        id: "title",
        Cell: ({ row }: { row: any }) => (
          <Thumbnail
            href={`/articles/${row?.original?.id}`}
            imageUrl={row.original.bannerUrl}
            label={row?.original?.title}
          />
        ),
      },
      {
        Header: (
          <SortingTableHeader
            onClick={() => handleSorting(FieldName.Status)}
            sortDirection={sortBy === FieldName.Status ? sortDirection : null}
            $style={{ justifyContent: "center" }}
          >
            Status
          </SortingTableHeader>
        ),
        id: "status",
        Cell: ({ row }: { row: any }) => (
          <Block display="flex" alignItems="center" justifyContent="center">
            {row.original.status === "Draft" && (
              <Pencil
                size={18}
                className={css({
                  verticalAlign: "middle",
                  display: "inline",
                  color: theme.colors.accent,
                })}
              />
            )}
            {row.original.status === "Pending" && (
              <FileLike
                size={18}
                className={css({
                  verticalAlign: "middle",
                  display: "inline",
                  color: theme.colors.warning,
                })}
              />
            )}
            {row.original.status === "Published" &&
              row.original.publishedAt &&
              !isDateFuture(row.original.publishedAt) && (
                <EyeCheck
                  size={18}
                  className={css({
                    verticalAlign: "middle",
                    display: "inline",
                    color: theme.colors.positive,
                  })}
                />
              )}
            {row.original.status === "Published" &&
              row.original.publishedAt &&
              isDateFuture(row.original.publishedAt) && (
                <Clock
                  size={18}
                  className={css({
                    verticalAlign: "middle",
                    display: "inline",
                    color: theme.colors.positive,
                  })}
                />
              )}
            {row.original.status === "Published" && !row.original.publishedAt && (
              <Pencil
                size={18}
                className={css({
                  verticalAlign: "middle",
                  display: "inline",
                  color: theme.colors.warning500,
                })}
              />
            )}
          </Block>
        ),
      },
      {
        Header: (
          <SortingTableHeader
            onClick={() => handleSorting(FieldName.PublishedAt)}
            sortDirection={
              sortBy === FieldName.PublishedAt ? sortDirection : null
            }
            $style={{ justifyContent: "flex-end" }}
          >
            Publikacja
          </SortingTableHeader>
        ),
        accessor: "publishedAt",
        Cell: ({ cell }: { cell: any }) => (
          <Block display="flex" alignItems="center" justifyContent="flex-end">
            <FormattedValue dataType="datetime" $style={{ textAlign: "right" }}>
              {cell.value}
            </FormattedValue>
          </Block>
        ),
      },
      {
        Header: "Kategoria",
        id: "categoryId",
        Cell: ({ row }: { row: any }) => (
          <FormattedValue
            dataType="model:article-categories"
            data={row?.original?.categoryId}
            withoutIcon
            showBlankWhenEmpty
          >
            {row?.original?.category?.name}
          </FormattedValue>
        ),
      },
      {
        Header: "Redaktor",
        id: "editor",
        Cell: ({ row }: { row: any }) => (
          <FormattedValue
            dataType="model:editors"
            data={row?.original?.editorId}
            withoutIcon
            showBlankWhenEmpty
          >
            {row?.original?.editor?.nick}
          </FormattedValue>
        ),
      },
      {
        Header: (
          <SortingTableHeader
            onClick={() => handleSorting(FieldName.IsRecommended)}
            sortDirection={
              sortBy === FieldName.IsRecommended ? sortDirection : null
            }
            $style={{ justifyContent: "center" }}
          >
            Polecany
          </SortingTableHeader>
        ),
        accessor: "isRecommended",
        Cell: ({ cell }: { cell: any }) => (
          <Block display="flex" alignItems="center" justifyContent="center">
            <FormattedValue dataType="boolean-icon">
              {cell.value}
            </FormattedValue>
          </Block>
        ),
      },
      {
        Header: (
          <SortingTableHeader
            onClick={() => handleSorting(FieldName.IsFeatured)}
            sortDirection={
              sortBy === FieldName.IsFeatured ? sortDirection : null
            }
            $style={{ justifyContent: "center" }}
          >
            Wyróżniony
          </SortingTableHeader>
        ),
        accessor: "isFeatured",
        Cell: ({ cell }: { cell: any }) => (
          <Block display="flex" alignItems="center" justifyContent="center">
            <FormattedValue dataType="boolean-icon">
              {cell.value}
            </FormattedValue>
          </Block>
        ),
      },
      {
        Header: (
          <SortingTableHeader
            onClick={() => handleSorting(FieldName.IsPinned)}
            sortDirection={sortBy === FieldName.IsPinned ? sortDirection : null}
            $style={{ justifyContent: "center" }}
          >
            Przypięty
          </SortingTableHeader>
        ),
        accessor: "isPinned",
        Cell: ({ cell }: { cell: any }) => (
          <Block display="flex" alignItems="center" justifyContent="center">
            <FormattedValue dataType="boolean-icon">
              {cell.value}
            </FormattedValue>
          </Block>
        ),
      },
      {
        Header: (
          <SortingTableHeader
            onClick={() => handleSorting(FieldName.IsSponsored)}
            sortDirection={
              sortBy === FieldName.IsSponsored ? sortDirection : null
            }
            $style={{ justifyContent: "center" }}
          >
            Sponsorowany
          </SortingTableHeader>
        ),
        accessor: "isSponsored",
        Cell: ({ cell }: { cell: any }) => (
          <Block display="flex" alignItems="center" justifyContent="center">
            <FormattedValue dataType="boolean-icon">
              {cell.value}
            </FormattedValue>
          </Block>
        ),
      },
      {
        Header: (
          <SortingTableHeader
            onClick={() => handleSorting(FieldName.ViewCount)}
            sortDirection={
              sortBy === FieldName.ViewCount ? sortDirection : null
            }
            $style={{ justifyContent: "flex-end" }}
          >
            Wyświetlenia
          </SortingTableHeader>
        ),
        accessor: "viewCount",
        Cell: ({ cell }: { cell: any }) => (
          <Block display="flex" alignItems="center" justifyContent="flex-end">
            <FormattedValue dataType="quota" showBlankWhenEmpty>
              {cell?.value}
            </FormattedValue>
          </Block>
        ),
      },
      {
        id: "actions",
        Cell: ({ row }: { row: any }) => (
          <div
            className={css({
              display: "flex",
              justifyContent: "flex-end",
            })}
          >
            <Button
              kind={KIND.secondary}
              size={SIZE.mini}
              $as="a"
              href={`/articles/${row?.original?.id}/edit`}
              onClick={(event: MouseEvent) => {
                event.preventDefault();
                history.push(`/articles/${row?.original?.id}/edit`);
              }}
            >
              Edytuj
            </Button>
            <Button
              kind={KIND.secondary}
              size={SIZE.mini}
              $as="a"
              href={`/articles/${row?.original?.id}`}
              onClick={(event: MouseEvent) => {
                event.preventDefault();
                history.push(`/articles/${row?.original?.id}`);
              }}
              $style={{ marginLeft: "10px" }}
            >
              Pokaż
            </Button>
          </div>
        ),
      },
    ],
    [sortBy, sortDirection]
  );

  useEffect(() => {
    if (isError)
      enqueue(
        {
          message: "Wystąpił błąd",
          overrides: SnackbarError,
          startEnhancer: ({ size }: { size: number }) => (
            <AlertCircle size={size} />
          ),
        },
        DURATION.long
      );
  }, [isError]);

  useEffect(() => {
    refetch();
    setIsFetching(true);
  }, [paginationNumber, pageNumber]);

  useEffect(() => {
    refetch();
  }, [sortBy, sortDirection]);

  useEffect(() => {
    refetch();
  }, [searchingPhrase]);

  useEffect(() => {
    if (data) refetch();
    if (data) categoriesRefetch();
    setIsFetching(true);
    pageNumber && setPaginationNumber(pageNumber);
  }, []);

  useEffect(() => {
    if (data) setIsFetching(false);
  }, [data]);

  useEffect(() => {
    refetch();
  }, [choosedCategory]);

  useEffect(() => {
    refetch();
  }, [choosedFilter]);

  return (
    <article>
      <Header
        title="Artykuły"
        recordsNum={data?.totalCount}
        labels={["Lista"]}
        buttons={[
          {
            label: "Dodaj artykuł",
            kind: KIND.secondary,
            startEnhancer: <Plus size={18} />,
            onClick: () => history.push("/articles/create"),
          },
        ]}
      />
      <Content>
        <Grid>
          <Cell span={12}>
            <Block marginBottom="30px">
              {isFetching ? (
                <Skeleton rows={0} height="50px" width="100%" animation />
              ) : (
                categoriesData &&
                categoriesData?.results?.map(
                  (category: ArticleCategory, index: number) => {
                    return (
                      !categoriesData?.results?.some(
                        (aC: ArticleCategory) => aC.parentId === category?.id
                      ) && (
                        <ObjectTag
                          key={`category${index + 1}`}
                          variant={
                            category.id === choosedCategory
                              ? "solid"
                              : "outlined"
                          }
                          onClick={() => {
                            setChoosedCategory((choosedCategory) =>
                              category.id === choosedCategory
                                ? undefined
                                : category.id
                            );
                          }}
                        >
                          {category.name}
                        </ObjectTag>
                      )
                    );
                  }
                )
              )}
            </Block>
          </Cell>
          <Cell span={12}>
            <Block marginBottom="10px">
              {isFetching ? (
                <Skeleton rows={0} height="50px" width="100%" animation />
              ) : (
                <>
                  {FilterableFields.map(
                    (field: { id: FieldName; label: string }) => (
                      <ObjectTag
                        key={`filter-${field.id}`}
                        onClick={() =>
                          setChoosedFilter(
                            field.id === choosedFilter ? "" : field.id
                          )
                        }
                        variant={
                          field.id === choosedFilter ? "solid" : "outlined"
                        }
                      >
                        {field.label}
                      </ObjectTag>
                    )
                  )}
                </>
              )}
            </Block>
          </Cell>
          <Cell span={8} />
          <Cell span={4}>
            <Block marginBottom="30px">
              <FormControl>
                <ControlledInput
                  control={control}
                  value={searchingInputValue}
                  onChange={(event) => {
                    setSearchingInputValue(event?.currentTarget?.value);
                    debouncedSetSearchingPhrase(event?.currentTarget?.value);
                  }}
                  name="searchingPhrase"
                  size={SIZE.compact}
                  placeholder="Wyszukaj"
                  clearable
                  endEnhancer={
                    isQueryFetching && !isQueryLoading && <Spinner size={18} />
                  }
                />
              </FormControl>
            </Block>
          </Cell>
          <Cell span={12} $style={{ position: "relative" }}>
            {isFetching ? (
              <Skeleton rows={0} height="300px" width="100%" animation />
            ) : (
              data && (
                <Table<Article>
                  columns={columns}
                  data={data?.results}
                  isHorizontalScroll
                />
              )
            )}
          </Cell>
          <Cell span={12}>
            {data && !!data?.totalCount && data?.totalCount > PAGE_SIZE && (
              <Pagination
                numPages={Math.ceil(data?.totalCount / pageSize)}
                currentPage={paginationNumber}
                onPageChange={setPaginationNumber}
              />
            )}
          </Cell>
        </Grid>
      </Content>
    </article>
  );
}
