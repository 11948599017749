import { OutputData } from "@editorjs/editorjs";

import { FIELDS, FieldsGroup } from "../../fields.d";

export type FormInputs = {
  title: string;
  slug: string;
  lead: string;
  summary?: string;
  bannerUrl?: string;
  commentRewardPointCount: number;
  attributes?: { id?: string; label?: string }[];
  publishedAt: string | null;
  status?: string;
  statusSelect?: { id: string; label?: string }[];
  isPopular?: boolean;
  isUnpopular?: boolean;
  isSponsored?: boolean;
  isCommentingDisabled: boolean;
  isPinned?: boolean;
  isRecommended?: boolean;
  isFeatured?: boolean;
  hasRecommendedBadge?: boolean;
  hasPowerBadge?: boolean;
  popularityViewCountThreshold: number;
  category?: { id?: string; label?: string }[];
  categoryId?: any;
  editors?: { id: number; label?: string }[];
  editorId?: number;
  pages?: { id?: number; content: Record<string, any>[]; position?: number }[];
  tags?: { id: number; label?: string }[];
  tagIds?: number[] | [];
  labels?: { id: number; label?: string }[];
  labelIds?: number[];
  games?: { id: number; label?: string }[];
  gameId?: number;
  body?: OutputData[];
  trackingCode?: string;
  sections?: { id: number; label?: string }[];
  sectionIds?: number[];
};

export const ARTICLES_FIELDS: FieldsGroup[] = [
  {
    id: "publication",
    label: "Publikacja",
    fields: [
      {
        id: "status",
        label: "Status",
        type: FIELDS.Input,
        dataType: "article-status",
        span: 4,
        show: { visible: true },
        create: { visible: false },
        edit: { visible: false },
      },
      {
        id: "statusSelect",
        label: "Status publikacji",
        type: FIELDS.ArticlesStatusSelect,
        span: 4,
        show: { visible: false },
        create: { visible: true },
        edit: { visible: true },
      },
      {
        id: "publishedAt",
        label: "Data publikacji",
        type: FIELDS.Input,
        span: 4,
        show: { visible: true },
        create: { visible: false },
        edit: { visible: false },
        dataType: "datetime",
      },
      {
        id: "publishedAt",
        label: "",
        type: FIELDS.DateTimePicker,
        span: 8,
        show: { visible: false },
        create: { visible: true },
        edit: { visible: true },
      },
    ],
  },
  {
    id: "basicInfo",
    label: "Podstawowe informacje",
    fields: [
      {
        id: "title",
        label: "Tytuł",
        type: FIELDS.Input,
        span: 12,
        show: { visible: true },
        create: { visible: true, required: true },
        edit: { visible: true, required: true },
      },
      {
        id: "slug",
        label: "Slug",
        type: FIELDS.Input,
        span: 12,
        show: { visible: true },
        create: { visible: true, required: true },
        edit: { visible: true, required: true },
      },
      {
        id: "lead",
        label: "Lead",
        type: FIELDS.TextArea,
        span: 12,
        show: { visible: true },
        create: { visible: true, required: true },
        edit: { visible: true, required: true },
      },
      {
        id: "tags",
        label: "Tagi",
        dataType: "set:tags",
        type: FIELDS.TagsSelect,
        span: 6,
        show: { visible: true },
        create: { visible: true },
        edit: { visible: true },
      },
      {
        id: "labels",
        label: "Etykiety",
        dataType: "set:labels",
        type: FIELDS.LabelsSelect,
        span: 6,
        show: { visible: true },
        create: { visible: true },
        edit: { visible: true },
      },
      {
        id: "category",
        label: "Kategoria",
        type: FIELDS.ArticlesCategoriesSelect,
        span: 6,
        show: { visible: false },
        create: { visible: true, required: true },
        edit: { visible: true, required: true },
      },
      {
        id: "editors",
        label: "Redaktor",
        type: FIELDS.EditorsSelect,
        span: 6,
        show: { visible: false },
        create: { visible: true, required: true },
        edit: { visible: true, required: true },
      },
      {
        id: "category",
        label: "Kategoria",
        type: FIELDS.Input,
        dataType: "model:article-categories",
        span: 6,
        show: { visible: true },
        create: { visible: false },
        edit: { visible: false },
      },
      {
        id: "editor",
        label: "Redaktor",
        type: FIELDS.Input,
        dataType: "model:editors",
        span: 6,
        show: { visible: true },
        create: { visible: false },
        edit: { visible: false },
      },
      {
        id: "author",
        label: "Autor",
        type: FIELDS.Input,
        dataType: "model:users",
        span: 4,
        show: { visible: true },
        create: { visible: false },
        edit: { visible: false },
      },
      {
        id: "createdAt",
        label: "Data utworzenia",
        type: FIELDS.Input,
        span: 4,
        show: { visible: true },
        create: { visible: false },
        edit: { visible: false },
        dataType: "datetime",
      },
      {
        id: "updatedAt",
        label: "Data aktualizacji",
        type: FIELDS.Input,
        span: 4,
        show: { visible: true },
        create: { visible: false },
        edit: { visible: false },
        dataType: "datetime",
      },
      {
        id: "sections",
        label: "Sekcje",
        type: FIELDS.SectionsSelect,
        span: 12,
        show: { visible: true },
        create: { visible: true },
        edit: { visible: true },
        dataType: "set:sections",
      },
    ],
  },
  {
    id: "attributes",
    label: "Atrybuty",
    fields: [
      {
        id: "isPopular",
        label: "Popularny",
        caption: "Oznaczenie jako Hit.",
        type: FIELDS.Checkbox,
        dataType: "boolean",
        span: 3,
        show: { visible: true },
        create: { visible: true },
        edit: { visible: true },
      },
      {
        id: "isUnpopular",
        label: "Niepopularny",
        caption: "Ukrycie z wyróżnionych sekcji na stronie.",
        type: FIELDS.Checkbox,
        dataType: "boolean",
        span: 3,
        show: { visible: true },
        create: { visible: true },
        edit: { visible: true },
      },
      {
        id: "isCommentingDisabled",
        label: "Blokada komentarzy",
        caption: "Ukrycie i uniemożliwienie komentowania artykułu.",
        type: FIELDS.Checkbox,
        dataType: "boolean",
        span: 3,
        show: { visible: true },
        create: { visible: true },
        edit: { visible: true },
      },
      {
        id: "isRecommended",
        label: "Polecany",
        caption: "Wyświetlanie w sekcji Polecane na stronie głównej.",
        type: FIELDS.Checkbox,
        dataType: "boolean",
        span: 3,
        show: { visible: true },
        create: { visible: true },
        edit: { visible: true },
      },
      {
        id: "isPinned",
        label: "Przypięty",
        caption: "Wyświetlanie w swojej kategorii na stronie głównej.",
        type: FIELDS.Checkbox,
        span: 3,
        show: { visible: true },
        create: { visible: true },
        edit: { visible: true },
        dataType: "boolean",
      },
      {
        id: "isSponsored",
        label: "Sponsorowany",
        caption: "Oznaczenie sponsorowanego artykułu.",
        type: FIELDS.Checkbox,
        dataType: "boolean",
        span: 3,
        show: { visible: true },
        create: { visible: true },
        edit: { visible: true },
      },
      {
        id: "isFeatured",
        label: "Wyróżniony",
        caption: "Wyświetlanie na początku list w swojej kategorii.",
        type: FIELDS.Checkbox,
        dataType: "boolean",
        span: 3,
        show: { visible: true },
        create: { visible: true },
        edit: { visible: true },
      },
    ],
  },
  {
    id: "badges",
    label: "Odznaki",
    fields: [
      {
        id: "hasRecommendedBadge",
        label: "CD-Action Poleca",
        type: FIELDS.Checkbox,
        dataType: "boolean",
        span: 3,
        show: { visible: true },
        create: { visible: true },
        edit: { visible: true },
      },
      {
        id: "hasPowerBadge",
        label: "CD-Action Power",
        type: FIELDS.Checkbox,
        dataType: "boolean",
        span: 3,
        show: { visible: true },
        create: { visible: true },
        edit: { visible: true },
      },
    ],
  },
  {
    id: "statistics",
    label: "Statystyki",
    fields: [
      {
        id: "readCount",
        label: "Liczba przeczytań",
        dataType: "quota",
        type: FIELDS.Input,
        span: 3,
        show: { visible: true },
        create: { visible: false },
        edit: { visible: false },
      },
      {
        id: "viewCount",
        label: "Liczba wejść",
        dataType: "quota",
        type: FIELDS.Input,
        span: 3,
        show: { visible: true },
        create: { visible: false },
        edit: { visible: false },
      },
      {
        id: "commentRewardPointCount",
        label: "Liczba punktów za skomentowanie",
        dataType: "quota",
        type: FIELDS.NumberInput,
        span: 3,
        show: { visible: true },
        create: { visible: true, required: true },
        edit: { visible: true, required: true },
      },
      {
        id: "popularityViewCountThreshold",
        label: "Min. liczba wejść dla popularności",
        dataType: "quota",
        type: FIELDS.NumberInput,
        span: 3,
        show: { visible: true },
        create: { visible: true, required: true },
        edit: { visible: true, required: true },
      },
    ],
  },
  {
    id: "content",
    label: "Treść",
    fields: [
      {
        id: "body",
        label: "",
        span: 12,
        type: FIELDS.Editor,
        show: { visible: true },
        create: { visible: true },
        edit: { visible: true },
      },
      {
        id: "trackingCode",
        label: "Kod śledzenia",
        caption: "Ukryty kod HTML niewidoczny dla użytkownika.",
        span: 12,
        type: FIELDS.TextArea,
        show: { visible: true },
        create: { visible: true },
        edit: { visible: true },
      },
    ],
  },
  {
    id: "bindings",
    label: "Powiązania",
    fields: [
      {
        id: "games",
        label: "Karta gry",
        span: 12,
        dataType: "model:games",
        type: FIELDS.GamesSelect,
        show: { visible: true, accessor: ["game", "originalTitle"] },
        create: { visible: true },
        edit: { visible: true },
      },
    ],
  },
  {
    id: "banner",
    label: "Baner",
    fields: [
      {
        id: "bannerUrl",
        label: "",
        type: FIELDS.ImagePicker,
        show: { visible: false },
        create: { visible: true },
        edit: { visible: true },
      },
    ],
  },
];
